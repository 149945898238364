import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { BarChart } from "../components/ui/BarChart";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";
import { addDays, format } from "date-fns";
import { getBrandStatistics } from '../services/brandService';
import { Star, StarHalf, BarChart2, Globe, LineChart, Share2, Users, Calendar } from 'lucide-react';

const BrandStatistics = ({ brandId }) => {
  // Основные состояния
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Состояния для дат
  const [startDate, setStartDate] = useState(addDays(new Date(), -30));
  const [endDate, setEndDate] = useState(new Date());

  // Состояния для сравнения брендов
  const [selectedBrand, setSelectedBrand] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('all_countries');
  const [selectedGender, setSelectedGender] = useState('all_genders');
  const [selectedAge, setSelectedAge] = useState('all_ages');

  // Получение данных
  useEffect(() => {
    const fetchStats = async () => {
      if (!brandId) return;

      try {
        // Set default date range to last 7 days if not set
        const defaultEndDate = new Date();
        const defaultStartDate = addDays(defaultEndDate, -7);

        const data = await getBrandStatistics(brandId, {
          startDate: (startDate || defaultStartDate).toISOString(),
          endDate: (endDate || defaultEndDate).toISOString()
        });

        if (!data) throw new Error('No data received');

console.log("Initial data from server:", data); // Проверяем входные данные

console.log("Raw data:", data); // Добавляем полный лог данных

// Создаем Map для группировки данных по дням
const dailyStatsMap = new Map();
const currentStartDate = startDate || defaultStartDate;
const currentEndDate = endDate || defaultEndDate;

// Получаем battleHistory напрямую из data
const battleHistory = data.battleHistory || [];
console.log("Battle history:", battleHistory);

battleHistory.forEach(battle => {
  const dateKey = format(new Date(battle.timestamp), 'yyyy-MM-dd');
  console.log(`Processing battle for ${dateKey}:`, battle);
  
  if (!dailyStatsMap.has(dateKey)) {
    dailyStatsMap.set(dateKey, {
      date: dateKey,
      wins: 0,
      losses: 0,
      totalBattles: 0
    });
  }
  
  const dayStats = dailyStatsMap.get(dateKey);
  dayStats.totalBattles++;
  
  if (battle.result === 'win') {
    dayStats.wins++;
  } else if (battle.result === 'loss') {
    dayStats.losses++;
  }
  
  console.log(`Updated stats for ${dateKey}:`, dailyStatsMap.get(dateKey));
});

// Заполняем пропущенные даты между startDate и endDate
let currentDate = new Date(currentStartDate);
while (currentDate <= currentEndDate) {
  const dateKey = format(currentDate, 'yyyy-MM-dd');
  if (!dailyStatsMap.has(dateKey)) {
    dailyStatsMap.set(dateKey, {
      date: dateKey,
      wins: 0,
      losses: 0,
      totalBattles: 0
    });
  }
  currentDate = addDays(currentDate, 1);
}

// Преобразуем Map в массив и сортируем по дате
const dailyStats = Array.from(dailyStatsMap.values())
  .sort((a, b) => new Date(a.date) - new Date(b.date));

console.log("Final daily stats:", dailyStats);

        setStats({
          totalBattles: data.totalBattles || 0,
          totalWins: data.totalWins || 0,
          rating: data.rating || 0,
          dailyStats: dailyStats,
          countryStats: data.countryStats?.map(country => ({
            ...country,
            dailyStats: (country.dailyStats || []).map(stat => ({
              date: stat.date,
              wins: Number(stat.wins) || 0,
              losses: Number(stat.losses) || 0,
              totalBattles: Number(stat.totalBattles) || 0
            })),
            demographics: {
              genderStats: (country.demographics?.genderStats || []).map(stat => ({
                gender: stat.gender,
                wins: Number(stat.wins) || 0,
                losses: Number(stat.losses) || 0,
                totalBattles: Number(stat.totalBattles) || 0
              })),
              ageStats: (country.demographics?.ageStats || []).map(stat => ({
                ageRange: stat.ageRange,
                wins: Number(stat.wins) || 0,
                losses: Number(stat.losses) || 0,
                totalBattles: Number(stat.totalBattles) || 0
              }))
            }
          })) || [],
          demographics: {
            genderStats: (data.demographics?.genderStats || []).map(stat => ({
              gender: stat.gender,
              wins: Number(stat.wins) || 0,
              losses: Number(stat.losses) || 0,
              totalBattles: Number(stat.totalBattles) || 0,
              percentage: stat.totalBattles > 0 ? ((stat.wins / stat.totalBattles) * 100).toFixed(1) : '0'
            })),
            ageStats: (data.demographics?.ageStats || []).map(stat => ({
              ageRange: stat.ageRange,
              wins: Number(stat.wins) || 0,
              losses: Number(stat.losses) || 0,
              totalBattles: Number(stat.totalBattles) || 0,
              percentage: stat.totalBattles > 0 ? ((stat.wins / stat.totalBattles) * 100).toFixed(1) : '0'
            }))
          },
          socialMedia: {
            websiteClicks: Number(data.websiteClicks) || 0,
            platforms: (data.socialMediaClicks || []).map(platform => ({
              name: platform.name,
              clicks: Number(platform.clicks) || 0
            }))
          },
          comparisons: data.comparisons?.map(comparison => ({
            ...comparison,
            dailyStats: (comparison.dailyStats || []).map(stat => ({
              date: stat.date,
              wins: Number(stat.wins) || 0,
              losses: Number(stat.losses) || 0,
              totalBattles: Number(stat.totalBattles) || 0
            })),
            countryStats: (comparison.countryStats || []).map(stat => ({
              country: stat.country,
              wins: Number(stat.wins) || 0,
              losses: Number(stat.losses) || 0,
              totalBattles: Number(stat.totalBattles) || 0,
              demographics: {
                genderStats: (stat.demographics?.genderStats || []).map(g => ({
                  gender: g.gender,
                  wins: Number(g.wins) || 0,
                  losses: Number(g.losses) || 0,
                  totalBattles: Number(g.totalBattles) || 0
                })),
                ageStats: (stat.demographics?.ageStats || []).map(a => ({
                  ageRange: a.ageRange,
                  wins: Number(a.wins) || 0,
                  losses: Number(a.losses) || 0,
                  totalBattles: Number(a.totalBattles) || 0
                }))
              }
            })),
            wins: Number(comparison.wins) || 0,
            losses: Number(comparison.losses) || 0,
            totalBattles: Number(comparison.totalBattles) || 0,
            winRate: comparison.totalBattles > 0
              ? ((comparison.wins / comparison.totalBattles) * 100).toFixed(1)
              : '0'
          })) || []
        });
      } catch (error) {
        console.error('Error fetching stats:', error);
        setError('Failed to fetch statistics');
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, [brandId, startDate, endDate]);

  // Функция для получения статистики по стране
  const getCountryStats = () => {
    if (!selectedCountry || selectedCountry === 'all_countries' || !stats?.countryStats) return null;
    const countryData = stats.countryStats.find(stat => stat.country === selectedCountry);
    if (!countryData) return null;

    return {
      totalBattles: countryData.totalBattles || 0,
      wins: countryData.wins || 0,
      demographics: {
        genderStats: countryData.demographics?.genderStats || [],
        ageStats: countryData.demographics?.ageStats || []
      },
      dailyStats: countryData.dailyStats || []
    };
  };

  // Функция для получения статистики сравнения
  const getComparisonStats = () => {
    if (!selectedBrand || !stats?.comparisons) return {
      wins: 0,
      losses: 0,
      winRate: '0',
      dailyStats: []
    };

    const comparison = stats.comparisons.find(c => c.competitorBrandId === selectedBrand);
    if (!comparison) return {
      wins: 0,
      losses: 0,
      winRate: '0',
      dailyStats: []
    };

    let filteredStats = { ...comparison };

    // Apply filters
    if (selectedCountry !== 'all_countries' && comparison.countryStats) {
      const countryStat = comparison.countryStats.find(s => s.country === selectedCountry);
      if (countryStat) {
        filteredStats = {
          ...countryStat,
          dailyStats: countryStat.dailyStats || []
        };
      }
    }

    if (selectedGender !== 'all_genders' && filteredStats.demographics?.genderStats) {
      const genderStat = filteredStats.demographics.genderStats.find(s => s.gender === selectedGender);
      if (genderStat) {
        filteredStats.wins = genderStat.wins;
        filteredStats.losses = genderStat.losses;
      }
    }

    if (selectedAge !== 'all_ages' && filteredStats.demographics?.ageStats) {
      const ageStat = filteredStats.demographics.ageStats.find(s => s.ageRange === selectedAge);
      if (ageStat) {
        filteredStats.wins = ageStat.wins;
        filteredStats.losses = ageStat.losses;
      }
    }

    // Calculate win rate
    const totalBattles = filteredStats.wins + filteredStats.losses;
    const winRate = totalBattles > 0
      ? ((filteredStats.wins / totalBattles) * 100).toFixed(1)
      : '0';

    return {
      ...filteredStats,
      winRate
    };
  };

  // Вспомогательные функции
  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 >= 0.5;
    const stars = [];

    for (let i = 0; i < 5; i++) {
      if (i < fullStars) {
        stars.push(<Star key={i} className="text-[#e0ff89] inline w-4 h-4" />);
      } else if (i === fullStars && hasHalfStar) {
        stars.push(<StarHalf key={i} className="text-[#e0ff89] inline w-4 h-4" />);
      } else {
        stars.push(<Star key={i} className="text-gray-400 inline w-4 h-4" />);
      }
    }
    return stars;
  };

  if (loading) return <div className="text-center py-4 text-white">Loading...</div>;
  if (error) return <div className="text-center py-4 text-red-500">{error}</div>;
  if (!stats) return <div className="text-center py-4 text-white">No statistics available.</div>;

  return (
    <Card className="bg-[#1a1a1a] text-white rounded-[20px] overflow-hidden">
      <CardHeader className="bg-[#282828] px-4 py-3">
        <div className="flex justify-between items-center">
          <CardTitle className="text-lg font-bold flex items-center gap-2">
            <BarChart2 className="w-5 h-5 text-[#e0ff89]" />
            Brand Statistics
          </CardTitle>
          <div className="flex items-center gap-2">
            {renderStars(stats.rating)}
            <span className="text-xl font-bold">{stats.rating.toFixed(2)}</span>
          </div>
        </div>
      </CardHeader>

      <CardContent className="p-4 space-y-6">
        {/* Основные показатели и график по дням */}
        <div>
          {/* Дата пикер */}
          <div className="flex gap-4 mb-4">
            <div className="flex-1 relative">
              <label className="text-sm text-gray-400 mb-1 block">Start Date</label>
              <input
                type="date"
                value={format(startDate, 'yyyy-MM-dd')}
                onChange={(e) => {
                  const date = new Date(e.target.value);
                  setStartDate(date);
                }}
                className="w-full bg-[#282828] text-white border border-gray-600 rounded-md p-2"
              />
            </div>
            <div className="flex-1 relative">
              <label className="text-sm text-gray-400 mb-1 block">End Date</label>
              <input
                type="date"
                value={format(endDate, 'yyyy-MM-dd')}
                onChange={(e) => {
                  const date = new Date(e.target.value);
                  setEndDate(date);
                }}
                className="w-full bg-[#282828] text-white border border-gray-600 rounded-md p-2"
              />
            </div>
          </div>

          {/* Основные показатели в строку */}
          <div className="grid grid-cols-3 gap-4 mb-4">
            <div className="bg-[#282828] p-4 rounded-xl">
              <p className="text-[#e0ff89] text-sm mb-2">Total Battles</p>
              <p className="text-2xl font-bold">{stats.totalBattles}</p>
            </div>
            <div className="bg-[#282828] p-4 rounded-xl">
              <p className="text-[#e0ff89] text-sm mb-2">Total Wins</p>
              <p className="text-2xl font-bold">{stats.totalWins}</p>
            </div>
            <div className="bg-[#282828] p-4 rounded-xl">
              <p className="text-[#e0ff89] text-sm mb-2">Win Rate</p>
              <p className="text-2xl font-bold">
                {stats.totalBattles > 0
                  ? ((stats.totalWins / stats.totalBattles) * 100).toFixed(1)
                  : '0'}%
              </p>
            </div>
          </div>

          {/* График по дням */}
<div className="bg-[#282828] p-4 rounded-xl">
  <h4 className="text-sm mb-4">Daily Statistics</h4>
  <div className="h-64">
    <BarChart
      data={(() => {
        // Создаем Map для группировки битв по дням
        const dailyBattlesMap = new Map();
        
        // Обрабатываем battleHistory
        stats.battleHistory?.forEach(battle => {
          const date = new Date(battle.timestamp);
          const dateKey = format(date, 'yyyy-MM-dd');
          
          if (!dailyBattlesMap.has(dateKey)) {
            dailyBattlesMap.set(dateKey, {
              wins: 0,
              losses: 0,
              totalBattles: 0
            });
          }
          
          const dayStats = dailyBattlesMap.get(dateKey);
          dayStats.totalBattles++;
          
          if (battle.result === 'win') {
            dayStats.wins++;
          } else if (battle.result === 'loss') {
            dayStats.losses++;
          }
        });

        // Преобразуем Map в массив данных для графика
        const chartData = Array.from(dailyBattlesMap.entries())
          .map(([date, stats]) => ({
            name: format(new Date(date), 'MMM dd'),
            wins: stats.wins,
            losses: stats.losses,
            total: stats.totalBattles
          }))
          .filter(day => {
            const dayDate = new Date(day.name);
            return dayDate >= startDate && dayDate <= endDate;
          })
          .sort((a, b) => new Date(a.name) - new Date(b.name));

        return chartData;
      })()}
    />
  </div>
</div>
        </div>

        {/* Сравнение брендов */}
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <p className="text-[#e0ff89] text-sm flex items-center gap-2">
              <LineChart className="w-4 h-4" />
              Brand Comparison
            </p>
          </div>

          <Select value={selectedBrand} onValueChange={setSelectedBrand}>
            <SelectTrigger className="bg-black text-white border-gray-600">
              <SelectValue placeholder="Select Brand to Compare" />
            </SelectTrigger>
            <SelectContent className="bg-[#1a1a1a] border-gray-600 z-[999]">
              {stats?.comparisons && stats.comparisons.length > 0 ?
                stats.comparisons.map((comp) => (
                  <SelectItem
                    key={comp.competitorBrandId}
                    value={comp.competitorBrandId || 'undefined'}
                  >
                    <div className="flex items-center gap-2">
                      {comp.competitorLogo && (
                        <img
                          src={comp.competitorLogo}
                          alt={comp.competitorBrand}
                          className="w-6 h-6 rounded-full"
                        />
                      )}
                      <span>{comp.competitorBrand}</span>
                    </div>
                  </SelectItem>
                )) :
                <SelectItem value="no_data">No comparisons available</SelectItem>
              }
            </SelectContent>
          </Select>

          {selectedBrand && (
            <>
              {/* Фильтры сравнения */}
              <div className="grid grid-cols-3 gap-4">
                <Select value={selectedCountry} onValueChange={setSelectedCountry}>
                  <SelectTrigger className="bg-black text-white border-gray-600">
                    <SelectValue placeholder="All Countries" />
                  </SelectTrigger>
                  <SelectContent className="bg-[#1a1a1a] border-gray-600 z-[999]">
                    <SelectItem value="all_countries">All Countries</SelectItem>
                    {stats?.countryStats?.map((stat) => (
                      <SelectItem key={stat.country} value={stat.country || 'undefined'}>
                        {stat.country}
                      </SelectItem>
                    ))}
                    {(!stats?.countryStats || stats.countryStats.length === 0) && (
                      <SelectItem value="no_countries">No countries available</SelectItem>
                    )}
                  </SelectContent>
                </Select>

                <Select value={selectedGender} onValueChange={setSelectedGender}>
                  <SelectTrigger className="bg-black text-white border-gray-600">
                    <SelectValue placeholder="All Genders" />
                  </SelectTrigger>
                  <SelectContent className="bg-[#1a1a1a] border-gray-600 z-[999]">
                    <SelectItem value="all_genders">All Genders</SelectItem>
                    {stats?.demographics?.genderStats?.map((stat) => (
                      <SelectItem key={stat.gender} value={stat.gender || 'undefined'}>
                        {stat.gender}
                      </SelectItem>
                    ))}
                    {(!stats?.demographics?.genderStats || stats.demographics.genderStats.length === 0) && (
                      <SelectItem value="no_genders">No gender data available</SelectItem>
                    )}
                  </SelectContent>
                </Select>

                <Select value={selectedAge} onValueChange={setSelectedAge}>
                  <SelectTrigger className="bg-black text-white border-gray-600">
                    <SelectValue placeholder="All Ages" />
                  </SelectTrigger>
                  <SelectContent className="bg-[#1a1a1a] border-gray-600 z-[999]">
                    <SelectItem value="all_ages">All Ages</SelectItem>
                    {stats?.demographics?.ageStats?.map((stat) => (
                      <SelectItem key={stat.ageRange} value={stat.ageRange || 'undefined'}>
                        {stat.ageRange}
                      </SelectItem>
                    ))}
                    {(!stats?.demographics?.ageStats || stats.demographics.ageStats.length === 0) && (
                      <SelectItem value="no_ages">No age data available</SelectItem>
                    )}
                  </SelectContent>
                </Select>
              </div>

              {/* Статистика сравнения */}
              <div className="bg-[#282828] p-4 rounded-xl">
                <div className="mb-4">
                  <h4 className="text-lg font-bold">Comparison Results</h4>
                  <div className="grid grid-cols-2 gap-4 mt-2">
                    <div>
                      <p className="text-sm text-gray-400">Wins</p>
                      <p className="text-2xl font-bold text-[#e0ff89]">
                        {getComparisonStats().wins}
                      </p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-400">Losses</p>
                      <p className="text-2xl font-bold text-red-400">
                        {getComparisonStats().losses}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="h-64">
                  <BarChart
                    data={(getComparisonStats().dailyStats || [])
                      .filter(day => {
                        const dayDate = new Date(day.date);
                        return dayDate >= startDate && dayDate <= endDate;
                      })
                      .map(day => ({
                        name: format(new Date(day.date), 'MMM dd'),
                        value: Number(day.wins || 0)
                      }))
                    }
                  />
                </div>
              </div>
            </>
          )}
        </div>

        {/* Социальная активность */}
        <div className="space-y-4">
          <p className="text-[#e0ff89] text-sm flex items-center gap-2">
            <Share2 className="w-4 h-4" />
            Social Media Activity
          </p>

          <div className="bg-[#282828] p-4 rounded-xl">
            <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 mb-4">
              <div>
                <p className="text-sm text-gray-400">Website Clicks</p>
                <p className="text-xl font-bold">{stats.socialMedia.websiteClicks}</p>
              </div>
              {stats.socialMedia.platforms.map((platform) => (
                <div key={platform.name}>
                  <p className="text-sm text-gray-400">{platform.name}</p>
                  <p className="text-xl font-bold">{platform.clicks}</p>
                </div>
              ))}
            </div>

            <div className="h-64">
              <BarChart
                data={[
                  { name: 'Website', value: stats.socialMedia.websiteClicks },
                  ...stats.socialMedia.platforms.map(p => ({
                    name: p.name,
                    value: p.clicks
                  }))
                ]}
              />
            </div>
          </div>
        </div>

        {/* Демографическая статистика */}
        <div className="space-y-4">
          <p className="text-[#e0ff89] text-sm flex items-center gap-2">
            <Users className="w-4 h-4" />
            Demographics Overview
          </p>

          {/* Статистика по полу */}
          <div className="bg-[#282828] p-4 rounded-xl">
            <h4 className="text-sm mb-4">Gender Statistics</h4>
            <div className="grid grid-cols-2 gap-4 mb-4">
              {stats.demographics.genderStats.map((stat) => (
                <div key={stat.gender} className="bg-[#1a1a1a] p-3 rounded-lg">
                  <p className="text-sm capitalize">{stat.gender}</p>
                  <p className="text-2xl font-bold">
                    {((stat.wins / stat.totalBattles) * 100).toFixed(1)}%
                  </p>
                  <p className="text-sm text-gray-400">
                    {stat.totalBattles} battles
                  </p>
                </div>
              ))}
            </div>
            <div className="h-48">
              <BarChart
                data={(stats.demographics.genderStats || []).map(stat => ({
                  name: stat.gender || '',
                  value: Number(stat.totalBattles) || 0
                }))}
              />
            </div>
          </div>

          {/* Статистика по возрасту */}
          <div className="bg-[#282828] p-4 rounded-xl">
            <h4 className="text-sm mb-4">Age Statistics</h4>
            <div className="grid grid-cols-3 gap-4 mb-4">
              {stats.demographics.ageStats.map((stat) => (
                <div key={stat.ageRange} className="bg-[#1a1a1a] p-3 rounded-lg">
                  <p className="text-sm">{stat.ageRange}</p>
                  <p className="text-2xl font-bold">
                    {((stat.wins / stat.totalBattles) * 100).toFixed(1)}%
                  </p>
                  <p className="text-sm text-gray-400">
                    {stat.totalBattles} battles
                  </p>
                </div>
              ))}
            </div>
            <div className="h-48">
              <BarChart
                data={stats.demographics.ageStats.map(stat => ({
                  name: stat.ageRange,
                  wins: stat.wins,
                  losses: stat.losses
                }))}
              />
            </div>
          </div>
        </div>

        {/* Статистика по странам */}
        <div className="space-y-4">
          <p className="text-[#e0ff89] text-sm flex items-center gap-2">
            <Globe className="w-4 h-4" />
            Country Statistics
          </p>

          <Select value={selectedCountry} onValueChange={setSelectedCountry}>
            <SelectTrigger className="bg-black text-white border-gray-600">
              <SelectValue placeholder="Select Country" />
            </SelectTrigger>
            <SelectContent className="bg-[#1a1a1a] border-gray-600 z-[999]">
              {stats?.countryStats?.length > 0 ? (
                stats.countryStats.map((stat) => (
                  <SelectItem key={stat.country} value={stat.country || 'undefined'}>
                    {stat.country}
                  </SelectItem>
                ))
              ) : (
                <SelectItem value="no_data">No countries available</SelectItem>
              )}
            </SelectContent>
          </Select>

          {selectedCountry !== 'all' && (
            <div className="space-y-4">
              {/* Основные показатели страны */}
              {getCountryStats() ? (
                <div className="grid grid-cols-3 gap-4">
                  <div className="bg-[#282828] p-4 rounded-xl">
                    <p className="text-sm text-gray-400">Total Battles</p>
                    <p className="text-2xl font-bold">
                      {getCountryStats()?.totalBattles || 0}
                    </p>
                  </div>
                  <div className="bg-[#282828] p-4 rounded-xl">
                    <p className="text-sm text-gray-400">Total Wins</p>
                    <p className="text-2xl font-bold text-[#e0ff89]">
                      {getCountryStats()?.wins || 0}
                    </p>
                  </div>
                  <div className="bg-[#282828] p-4 rounded-xl">
                    <p className="text-sm text-gray-400">Win Rate</p>
                    <p className="text-2xl font-bold">
                      {getCountryStats()?.totalBattles ?
                        ((getCountryStats().wins / getCountryStats().totalBattles) * 100).toFixed(1)
                        : '0'}%
                    </p>
                  </div>
                </div>
              ) : (
                <div className="bg-[#282828] p-4 rounded-xl text-center">
                  <p className="text-gray-400">No data available for selected country</p>
                </div>
              )}

              {/* Демографическая статистика страны */}
              {getCountryStats() && (
                <div className="grid grid-cols-2 gap-4">
                  {/* Статистика по полу */}
                  <div className="bg-[#282828] p-4 rounded-xl">
                    <h4 className="text-sm mb-3">Gender Distribution</h4>
                    <div className="space-y-2">
                      {getCountryStats()?.demographics?.genderStats?.map((stat) => (
                        <div key={stat.gender} className="flex justify-between items-center">
                          <span className="text-sm capitalize">{stat.gender}</span>
                          <span className="text-sm text-[#e0ff89]">
                            {((stat.wins / (stat.totalBattles || 1)) * 100).toFixed(1)}%
                          </span>
                        </div>
                      )) || <div className="text-gray-400">No gender data available</div>}
                    </div>
                  </div>

                  {/* Статистика по возрасту */}
                  <div className="bg-[#282828] p-4 rounded-xl">
                    <h4 className="text-sm mb-3">Age Distribution</h4>
                    <div className="space-y-2">
                      {getCountryStats()?.demographics?.ageStats?.map((stat) => (
                        <div key={stat.ageRange} className="flex justify-between items-center">
                          <span className="text-sm">{stat.ageRange}</span>
                          <span className="text-sm text-[#e0ff89]">
                            {((stat.wins / (stat.totalBattles || 1)) * 100).toFixed(1)}%
                          </span>
                        </div>
                      )) || <div className="text-gray-400">No age data available</div>}
                    </div>
                  </div>
                </div>
              )}

              {/* График по дням */}
              {getCountryStats() && (
                <div className="bg-[#282828] p-4 rounded-xl">
                  <h4 className="text-sm mb-4">Daily Statistics</h4>
                  <div className="h-64">
                    {getCountryStats()?.dailyStats?.length > 0 ? (
                      <BarChart
                        data={(getCountryStats()?.dailyStats || []).filter(day => {
                          const dayDate = new Date(day.date);
                          return dayDate >= startDate && dayDate <= endDate;
                        }).map(day => ({
                          name: format(new Date(day.date), 'MMM dd'),
                          value: Number(day.totalBattles) || 0
                        }))}
                      />
                    ) : (
                      <div className="flex items-center justify-center h-full text-gray-400">
                        <p>No data for selected date range</p>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default BrandStatistics;