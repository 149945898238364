import React, { useEffect, useState } from 'react';
import axios from 'axios';
import telegramService from '../services/telegramService';

const Auth = ({ onLogin }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const authenticateUser = async () => {
      try {
        telegramService.init();
        const initData = telegramService.getInitData();

        if (!initData && window.location.pathname === '/account') {
          // Если нет данных Telegram и мы на странице account, перенаправляем на бота
          window.location.href = 'https://t.me/BattleBrandsBot';
          return;
        }

        const response = await axios.post('/api/users/auth', null, {
          headers: {
            'X-Telegram-Init-Data': initData
          }
        });

        onLogin(response.data);
      } catch (err) {
        setError('Authentication failed. Please try again.');
        console.error('Auth error:', err);
      } finally {
        setLoading(false);
      }
    };

    authenticateUser();
  }, [onLogin]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return null;
};

export default Auth;